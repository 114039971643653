export const Rev = [
    {
        'id': '1',
        'title': 'Владелец загородного Прованс-отеля "4 Сезона"',
        'text': [
            'Услугами дизайн-студии мы пользовались впервые, и очень рады, что смогли сразу найти близких нам по ощущениям людей.',
            'Мы очень внимательно продумывали и проговаривали все детали и было удивительно, насколько быстро и точно они попадали в проект. Проект наполнялся стилем, мягкостью и теплотой и результат получился даже лучше , чем мы его себе представляли.',
            'Стилистическое решение проекта стало отправной точкой для ребрендинга всей базы отдыха в целом. Стиль "Прованс" - стал для нашего клуба визитной карточкой.',
            'Огромное спасибо за талантливую и профессиональную работу!',
        ]
    },
    {
        'id': '2',
        'title': 'Генеральный директор компании Smeg в России',
        'text': [
            'Перед дизайнерами стояло 3 сложные задачи:',
            '1. Обустроить на большой площади комфортное, стильное, приятное пространство, которое являлось бы визитной карточкой нашей компании.',
            '2. Органично вписать большое количество бытовой и профессиональной техники, в открытое пространство  шоу-рума. Стиль должен был напоминать Российские традиции, но не напрямую, а косвенно.',
            '3. Организовать комфортное рабочее пространство – Open Space – с зоной для переговоров, кухней, кабинетом руководителя и бухгалтерией.',
            'Нам было предложено несколько вариантов планировки и концепций идей. Решение принимали на уровне руководства материнской компании, расположенной в Италии. В результате с задачей дизайнеры справились на отлично!',
            'На этом сотрудничество не прекратилось. После офиса и шоу-рума в Санкт Петербурге, а также дизайнерского шоу-рума и офиса в Москве в стадии разработки находится проект магазина в одном из ТРЦ Крокус.',
            'Планируем и дальше работать с нашими замечательными дизайнерами!',
        ]
    },
    {
        'id': '3',
        'title': 'Собственник шоу-рума бытовой техники Smeg в Казани.',
        'text': [
            'Как владелец шоу-рума, я хочу выразить свою благодарность студии дизайна MaNe, которая разработала дизайн-проект интерьера нашего магазина.',
            'Они учли все наши пожелания и создали уникальное пространство, сочетающее в себе итальянский стиль, комфорт и функциональность.',
            'Светлые тона, гармоничная мебель и техника, мягкое и равномерное освещение, система кондиционирования - все это делает наш шоу-рум идеальным местом для покупки итальянской бытовой техники.',
        ]
    },
] 